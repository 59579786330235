@import './_variables.less';

.w-100 {
	width: 100% !important;
}

.weightage-container {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	width: 100%;

	.label-container {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.weightage-value {
			align-items: center;
			display: flex;
			flex-direction: row;
			font-size: @size-small2 !important;
			font-weight: @weight-semibold;
			justify-content: center;

			&.Beginner {
				color: @color-red-light;
			}

			&.Intermediate {
				color: @color-yellow-dark;
			}

			&.Advanced {
				color: @color-blue-dark;
			}

			&.Expert {
				color: @color-cyan;
			}

			.name {
				margin-left: 5px;
			}
		}
	}

	.ngx-slider {
		height: 15px;
		margin: 5px 0 0;

		&.Beginner {

			.ngx-slider-pointer {

				&:after {
					border-color: transparent transparent @color-red-light;
				}

				&:hover {

					&:after {
						border-color: transparent transparent @color-red-light;
					}
				}

				&.ngx-slider-active:after {
					border-color: transparent transparent @color-red-light;
				}
			}
		}

		&.Intermediate {

			.ngx-slider-pointer {

				&:after {
					border-color: transparent transparent @color-yellow-dark;
				}

				&:hover {

					&:after {
						border-color: transparent transparent @color-yellow-dark;
					}
				}

				&.ngx-slider-active:after {
					border-color: transparent transparent @color-yellow-dark;
				}
			}
		}

		&.Advanced {

			.ngx-slider-pointer {

				&:after {
					border-color: transparent transparent @color-blue-light;
				}

				&:hover {

					&:after {
						border-color: transparent transparent @color-blue-light;
					}
				}

				&.ngx-slider-active:after {
					border-color: transparent transparent @color-blue-light;
				}
			}
		}

		&.Expert {

			.ngx-slider-pointer {

				&:after {
					border-color: transparent transparent @color-cyan;
				}

				&:hover {

					&:after {
						border-color: transparent transparent @color-cyan;
					}
				}

				&.ngx-slider-active:after {
					border-color: transparent transparent @color-cyan;
				}
			}
		}

		.ngx-slider-bar {
			border-radius: 10px;
			height: 5px;
		}

		.ngx-slider-pointer {
			height: 5px;
			top: 11px;
			width: 1px;

			&:after {
				background-color: transparent;
				border-style: solid;
				border-width: 7px 9px 9px;
				height: 0;
				left: -10px;
				top: -12px;
				width: 0;
			}

			&:hover {

				&:after {
					background-color: transparent;
				}
			}

			&.ngx-slider-active:after {
				background-color: transparent;
			}
		}
	}
}

/* Progress Bar */
.progress {
	border-radius: 2px !important;
	background-color: @color-gray-lightest2;
	height: 15px;
	font-weight: @weight-bold;

	.bg-info {
		border-radius: 2px !important;
		background-color: @color-cyan !important;
	}
}