/* ALERT LESS */
@import './_variables.less';

.response-messsages-container {
	display: flex;
	flex-direction: column;
	width: 100%;

	.response-message {
		display: flex;
		flex-direction: column;
		width: 100%;		
	}
}

.alert {
	border-radius: 2px;
	border: 1px solid transparent;
	font-size: 14px;
	line-height: 1.8;
	margin-bottom: 30px;
	position: relative;
	padding: 12px 20px;
}

.alert-primary {
	color: #004085;
	background-color: #cce5ff;
	border-color: #b8daff;
}

.alert-secondary {
	color: #383d41;
	background-color: #e2e3e5;
	border-color: #d6d8db;
}

.alert-success {
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
}

.alert-danger {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
}

.alert-warning {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
}

.alert-info {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
}

.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
}

.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
}

/* Alertify JS related LESS */
.alertify-notifier .ajs-message.ajs-success {
	color: #155724 !important;
	background-color: #d4edda !important;
	border-color: #c3e6cb !important;
	box-shadow: none !important;
	text-shadow: none !important;
}

.alertify-notifier .ajs-message.ajs-error {
	color: #721c24 !important;
	background-color: #f8d7da !important;
	border-color: #f5c6cb !important;
	box-shadow: none !important;
	text-shadow: none !important;
}

.alertify-notifier .ajs-message.ajs-warning {
	color: #856404 !important;
	background-color: #fff3cd !important;
	border-color: #ffeeba !important;
	box-shadow: none !important;
	text-shadow: none !important;
}

.alertify-notifier.ajs-right  {
	right: 20px;
}

.alertify-notifier.ajs-top  {
	top: 80px;
}

/* General Notifier related LESS */
.alertify-notifier .ajs-message {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transition-duration: 250ms;
	transition-duration: 250ms;
	-webkit-transition-timing-function: linear;
	transition-timing-function: linear;
}
.alertify-notifier .ajs-message.ajs-visible {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}