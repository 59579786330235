/******    Mixins Definitions    *****/

/* Input Placeholder */
.input-placeholder(@color: #eee, @font-weight: 300) {
	::-webkit-input-placeholder {
		color: @color;
		font-weight: @font-weight;
	}
	:-moz-input-placeholder {
		color: @color;
		font-weight: @font-weight;
	}
	::-moz-input-placeholder {
		color: @color;
		font-weight: @font-weight;
	}
	::-ms-input-placeholder {
		color: @color;
		font-weight: @font-weight;
	}
}

/* Box Sizing */
.box-sizing(@sizing: border-box) {
	-ms-box-sizing: @sizing;
	-moz-box-sizing: @sizing;
	-webkit-box-sizing: @sizing;
	box-sizing: @sizing;
}

/* Opacity */
.opacity(@opacity: 0.5) {
	-moz-opacity: @opacity;
	-khtml-opacity: @opacity;
	-webkit-opacity: @opacity;
	opacity: @opacity;
	@opperc: @opacity * 100;
	-ms-filter: ~'progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})';
	filter: ~'alpha(opacity=@{opperc})';
}

/* Border */
.bordered(@top-color: #EEE, @right-color: #EEE, @bottom-color: #EEE, @left-color: #EEE) {
	border-top: solid 1px @top-color;
	border-left: solid 1px @left-color;
	border-right: solid 1px @right-color;
	border-bottom: solid 1px @bottom-color;
}

/* Border Radius */
.rounded(@radius: 2px) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

.border-radius(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
	-webkit-border-top-right-radius: @topright;
	-webkit-border-bottom-right-radius: @bottomright;
	-webkit-border-bottom-left-radius: @bottomleft;
	-webkit-border-top-left-radius: @topleft;
	-moz-border-radius-topright: @topright;
	-moz-border-radius-bottomright: @bottomright;
	-moz-border-radius-bottomleft: @bottomleft;
	-moz-border-radius-topleft: @topleft;
	border-top-right-radius: @topright;
	border-bottom-right-radius: @bottomright;
	border-bottom-left-radius: @bottomleft;
	border-top-left-radius: @topleft;
}

/* Box Shadow */
.box-shadow(@arguments) {
	-webkit-box-shadow: @arguments;
	-moz-box-shadow: @arguments;
	box-shadow: @arguments;
}

/* Black Variant of Inner Shadow */
.inner-shadow-black(@horizontal: 0, @vertical: 1px, @blur: 2px, @alpha: 0.4) {
	-webkit-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
	-moz-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
	box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
}

/* Any Color of Inner Shadow */
.inner-shadow(@horizontal: 0, @vertical: 1px, @blur: 2px, @spread: 2px, @color: #000) {
	-webkit-box-shadow: inset @horizontal @vertical @blur @spread @color;
	-moz-box-shadow: inset @horizontal @vertical @blur @spread @color;
	box-shadow: inset @horizontal @vertical @blur @spread @color;
}

/* Black Drop Shadow */
.drop-shadow-black(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.1) {
	-webkit-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
	-moz-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
	box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}

/* Any Color of Drop Shadow */
.drop-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @color: #000) {
	-webkit-box-shadow: @x-axis @y-axis @blur @color;
	-moz-box-shadow: @x-axis @y-axis @blur @color;
	box-shadow: @x-axis @y-axis @blur @color;
}

/* Linear Gradient */
.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
	background: @color;
	background: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0, @start),
		color-stop(1, @stop)
	);
	background: -ms-linear-gradient(bottom, @start, @stop);
	background: -moz-linear-gradient(center bottom, @start 0%, @stop 100%);
	background: -o-linear-gradient(@stop, @start);
	filter: e(
		%(
			"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
			@stop,
			@start
		)
	);
}

/* BW Gradient */
.bw-gradient(@color: #F5F5F5, @start: 0, @stop: 255) {
	background: @color;
	background: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0, rgb(@start, @start, @start)),
		color-stop(1, rgb(@stop, @stop, @stop))
	);
	background: -ms-linear-gradient(
		bottom,
		rgb(@start, @start, @start) 0%,
		rgb(@stop, @stop, @stop) 100%
	);
	background: -moz-linear-gradient(
		center bottom,
		rgb(@start, @start, @start) 0%,
		rgb(@stop, @stop, @stop) 100%
	);
	background: -o-linear-gradient(
		rgb(@stop, @stop, @stop),
		rgb(@start, @start, @start)
	);
	filter: e(
		%(
			"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
			rgb(@stop, @stop, @stop),
			rgb(@start, @start, @start)
		)
	);
}

/* Background Clip */
.background-clip(@argument: padding-box) {
	-moz-background-clip: @argument;
	-webkit-background-clip: @argument;
	background-clip: @argument;
}

/* Translate */
.translate(@x:0, @y:0) {
	.transform(translate(@x, @y));
}

/* All Transition */
.transition-all(@duration:0.2s, @ease:ease-out) {
	-webkit-transition: all @duration @ease;
	-moz-transition: all @duration @ease;
	-o-transition: all @duration @ease;
	transition: all @duration @ease;
}

/* Transition any Element Property */
.transition(@property: width, @duration:0.2s, @ease:ease-out) {
	-webkit-transition: @property @duration @ease;
	-moz-transition: @property @duration @ease;
	-o-transition: @property @duration @ease;
	transition: @property @duration @ease;
}

/* Transition Duration */
.transition-duration(@duration: 0.2s) {
	-moz-transition-duration: @duration;
	-webkit-transition-duration: @duration;
	-o-transition-duration: @duration;
	transition-duration: @duration;
}

/* Transform */
.transform(...) {
	-webkit-transform: @arguments;
	-moz-transform: @arguments;
	-o-transform: @arguments;
	-ms-transform: @arguments;
	transform: @arguments;
}

/* Rotation */
.rotation(@deg:5deg) {
	.transform(rotate(@deg));
}

/* Scale */
.scale(@ratio:1.5) {
	.transform(scale(@ratio));
}

/* User Select */
.user-select(@argument: none) {
	-webkit-user-select: @argument;
	-moz-user-select: @argument;
	-ms-user-select: @argument;
	user-select: @argument;
}

/* Slide In */
.slide-in {
	.opacity(@opacity: 1);
	top: 20%;
}

/* Fade In half */
.fade-in-half {
	.opacity(@opacity: 0.5);
}

/* Fade In full */
.fade-in {
	.opacity(@opacity: 1);
}

/* Shake CSS Starts */
.shake {
	display: inline-block;
	-webkit-transform-origin: center center;
	-ms-transform-origin: center center;
	transform-origin: center center;
}
.shake.shake-constant.shake-horizontal {
	-webkit-animation-name: shake-horizontal;
	-ms-animation-name: shake-horizontal;
	animation-name: shake-horizontal;
	-webkit-animation-duration: 100ms;
	-ms-animation-duration: 100ms;
	animation-duration: 100ms;
	-webkit-animation-iteration-count: 2;
	-ms-animation-iteration-count: 2;
	animation-iteration-count: 2;
	-webkit-animation-timing-function: ease-in-out;
	-ms-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-delay: 0s;
	-ms-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation-play-state: running;
	-ms-animation-play-state: running;
	animation-play-state: running;
}

/* Spin  */
.spin(@name: spin, @duration: 100ms, @iteration: 1, @function: linear) {
	-webkit-animation-name: @name;
	-webkit-animation-duration: @duration;
	-webkit-animation-iteration-count: @iteration;
	-webkit-animation-timing-function: @function;
	-moz-animation-name: @name;
	-moz-animation-duration: @duration;
	-moz-animation-iteration-count: @iteration;
	-moz-animation-timing-function: @function;
	-ms-animation-name: @name;
	-ms-animation-duration: @duration;
	-ms-animation-iteration-count: @iteration;
	-ms-animation-timing-function: @function;
}

.grayscale(@value: 100%) {
	-webkit-filter: grayscale(@value);
	-moz-filter: grayscale(@value);
	-ms-filter: grayscale(@value);
	filter: grayscale(@value);
}
