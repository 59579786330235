@import './_variables.less';

.btn {
	border-radius: 3px;
	color: @color-white;
	cursor: pointer;
	font-family: Roboto, helvetica neue, helvetica, arial, sans-sarif;
	font-size: @size-small2;
	font-weight: @weight-semibold;
	height: 40px;
	letter-spacing: 0.05em;
	outline: none !important;
	padding: 15px 20px !important;
	text-transform: uppercase;

	&[disabled] {
		opacity: 0.65;
	}
}

.btn-primary {
	background-color: @color-cyan !important;
	border: 1px solid @color-cyan !important;

	&:hover {
		background-color: @color-cyan-dark !important;
		border: 1px solid @color-cyan-dark !important;
	}
}

.btn-convert {
	border-radius: 2px;
	background-color: @color-white !important;
	border: 1px solid @color-gray-light3 !important;
	color: @color-gray-light1 !important;
	color: @color-cyan;
	font-size: @size-small4;
	margin-left: 10px;
	padding: 3px 10px !important;
	height: auto !important;

	&:hover {
		border: 1px solid @color-cyan !important;
		color: @color-cyan !important;
	}
}

.btn-bordered {
	background-color: rgba(255, 255, 255, 1.0) !important;
	border: 1px solid @color-cyan !important;
	color: @color-cyan !important;

	&:hover {
		background-color: @color-cyan !important;
		border: 1px solid @color-cyan !important;
		color: @color-white !important;
	}
}

.success-icon {
	
	&[disabled] {
		opacity: 1;
	}

	span.icon {
		background-image: url("../../images/icons/checkmark-white-64-64.png");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 30px 30px;
		display: flex;
		height: 40px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
	}
}

.btn-link {
	background-color: transparent !important;
	color: @color-gray-light1;
	flex-direction: row !important;
	font-size: @size-small2 !important;
	height: auto !important;

	&:hover {
		background-color: transparent !important;
		color: @color-gray-light1;
		text-decoration: underline !important;
	}

	span {
		letter-spacing: 0.1em !important;
	}

	span.proceed-arrow {
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		border: solid @color-gray-light1;
		border-width: 0 2px 2px 0;
		display: inline-block;
		margin: 0 0 0 5px;
		padding: 3px;
	}
}

.btn-error {
	box-shadow: none !important;
	background-color: transparent !important;
	color: @color-gray-light1 !important;
	font-size: @size-small3 !important;
	padding: 0 !important;
}