@import './assets/less/common/_variables.less';
@import './assets/less/common/_mixins.less';
@import './assets/less/common/_form.less';
@import './assets/less/common/_modal.less';
@import './assets/less/common/_alertify.less';
@import './assets/less/common/_guideline.less';
@import './assets/less/common/_tooltips.less';
@import './assets/less/common/_tour.less';
@import './assets/less/common/_buttons.less';
@import './assets/less/common/_controls.less';
@import './assets/less/common/_progress.less';
@import './assets/less/common/_pagination.less';
@import './assets/less/common/_animation.less';

//@import '~css-star-rating/css/star-rating.min.css';

* {
	.box-sizing(@sizing: border-box);
	outline: none;
}

html {
	margin: 0;
	padding: 0;
}

body {
	background-color: @color-gray-lightest2;
	color: @color-gray-medium4;
	font-size: 15px;
	/*font-family: 'Open Sans', sans-sarif;*/
	font-family: Roboto, helvetica neue, helvetica, arial, sans-sarif;
/*	font-family: "Roboto-Regular", "Roboto", sans-serif;*/
	font-weight: @weight-normal;
	height: 100%;
	margin: 0;
	min-height: 100%;
	overflow-x: hidden;
	padding: 0;
	width: 100%;
}

div {
	margin: 0;
	padding: 0;
}

a, span a {
	color: @color-cyan;
	cursor: pointer;
	margin: 0;
	padding: 0;
	text-decoration: none;

	&:hover {
		color: @color-cyan;
		text-decoration: none;
	}
}

a:not([href]):not([class]), span a:not([href]):not([class]) {
	color: @color-cyan;

	&:hover {
		color: @color-cyan;
		text-decoration: underline;
	}
}

img {
	border: none !important;
	outline: none !important;
	max-width: 100%;
}

button {
	border: none !important;
	outline: none !important;

	&:focus {
		border: none !important;
		outline: none !important;
	}
}

ul {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

ol {
//	display: flex;
	margin: 0;
	padding: 0 0 0 30px;
}

.view-section {

	ul, ol {
		flex-direction: column !important;
		padding-left: 15px;
		width: 100% !important;

		b {
			color: @color-black-dark1;
			font-style: italic;
			font-weight: @weight-semibold;
		}
	}

	ul {
		list-style: disc;
	}

	li {
		line-height: 1.8;
		padding: 0 0 10px 5px;
		width: 100% !important;

		&:last-child {
			padding-bottom: 0;
		}

		ul, ol {
			margin: 10px 0 0px;
		}
	}
}

table {
	.rounded(@radius: 1px);
	border: 1px solid @bordercolor-gray-light;
	border-collapse: collapse;
	border-spacing: 0;
	/*display: flex;*/
	font-family: Roboto, helvetica neue, helvetica, arial, sans-sarif;
	font-weight: @weight-normal;
	margin: 0;
	padding: 0;
	width: 100%;

	tr {
		border-bottom: 1px solid @bordercolor-gray-light;
		/*display: flex;*/
		margin: 0;
		width: 100%;

		&:last-child {
			border-bottom: none;
		}

		&:nth-child(2n-1) {
			background-color: @bordercolor-gray-light;
		}
	}

	th {
		background-color: @color-gray-light1;
		border-right: 1px solid @bordercolor-gray-light;
		color: @color-white;
		/*display: flex;*/
		font-size: @size-small2;
		font-weight: 400;
		margin: 0;
		padding: 10px 0;
		text-align: center;
		text-transform: uppercase;

		&:last-child {
			border-right: none;
		}
	}

	td {
		border-right: 1px solid @bordercolor-gray-light;
		color: @color-gray-medium2;
		/*display: flex;*/
		font-size: @size-small2;
		margin: 0;
		padding: 15px 0;
		text-align: center;

		&:last-child {
			border-right: none;
		}
	}
}

header,
footer {
	display: flex;
	margin: 0;
	padding: 0;
	width: 100%;
}

footer {
	/*position: relative;
	z-index: 2;*/
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
}

h2 {
	color: @color-black-dark3;
	font-size: @size-bigger3;
	font-weight: @weight-light;
}

input,
textarea {
	border-radius: 0px;
	/*font-family: 'Open Sans', sans-sarif;*/
	font-family: Roboto, helvetica neue, helvetica, arial, sans-sarif;
}

.clearfix {
	clear: both;
	float: none;
}

.hoz_line {
	border-bottom: 1px solid @bordercolor-line;
	margin: 0 auto;
	text-align: center;
	width: 100%;

	&.left {
		margin: 15px 0;
		text-align: left;
	}
}

.vert_line {
	background-color: @bordercolor-line;
	content: '';
	height: 100%;
	position: absolute;
	top: 0;
	width: 2px;
	z-index: -1;
}

.modules-container {
	min-height: calc(100vh - 80px) !important;
}

/* Custom Transparent Scrollbar */
.custom-scroll {

	&:hover {

		.ng-scrollbar-track {
			width: 8px !important;
		}

		.scrollbar-control {
			opacity: 1 !important;
		}
	}

	.scrollbar-control {
		opacity: 0 !important;
	}

	.ng-scroll-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100% !important;
	}
}

.ng-scroll-viewport {
	contain: none !important;
}

/* Left Menu Container */
.menu-container {
	//box-shadow: 2px 4px 8px 0px rgba(164, 170, 186, 0.2);
	background-color: @color-gray-lightest2;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 80px);
	padding: 0;
	width: 300px;

	.menu-list-container {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 80px);
		padding-right: 15px;
		padding-top: 25px;
		width: 100%;
	}

	.page-title  {
		background-color: @color-white;
		display: flex;
		flex-direction: column;
		padding: 30px 40px 0 60px;
		border-radius: 10px;

		.title  {
			color: @color-black-dark3;
			font-size: @size-big2;
			font-weight: @weight-normal ;
		}

		.hoz_line {
			width: 40px;
		}
	}

	.title  {
		color:@color-gray-menu   ;
		font-size: @size-medium3 ;
	}

	.desc {
		color: @color-gray-light1;
		font-size: @size-small2;
		margin-top: 2px;
	}


	ul.menu-list  {
		background-color: @color-white;
		display: flex;
		flex-direction: column;
		height: calc(100vh);
		padding-bottom: 10px;
		width: 100%;

		li.menu-item {
			background-position: 36px 30px;
			background-repeat: no-repeat;
			background-size: 20px 20px;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			padding: 30px 40px 20px 75px;
			width: 100%;

			&:hover {
				background-color: @bgcolor-default;
			}

			&.active {
				background-color: @bgcolor-default;
				border-right: 3px solid @color-cyan;


				.title  {
					color: @color-cyan;
					//font-weight: @weight-semibold;
				}
			}

			.type {
				color: @color-cyan;
				font-size: @size-small3;
				font-weight: @weight-semibold;
				letter-spacing: 0.05em;
				margin-bottom: 5px;
				text-transform: uppercase;
			}
		}
	}
}

/* Content for Menu Container */
.menu-content-container {
	display: flex;
	margin: 0;
	height: calc(100vh - 80px);
	position: relative;
	width: auto;

	.box-container {
		border-radius: 5px;
		background-color: @color-white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: calc(100vh - 80px);
		width: 100%;

		&.no-padding {
			padding: 0 !important;
		}
	}

	.box-content {
		display: flex;
		width: 100%;
	}
}


.menu-tips-container {
	background-color: @color-gray-lightest2;
	display: flex;
	min-height: calc(100vh - 80px);
	justify-content: center;
	position: relative;
	width: 350px;
}

/* Timeline CSS */
.timeline-container {
	border-left: 2px solid @color-cyan;
	display: flex;
	flex-direction: column;
	margin-left: 0px;
/*	width: calc(100% - 8px);*/
	width: 100%;

	> .field-group-wrapper {
		padding-left: 50px;
		position: relative;
	}

	.timeline-outercircle {
		border-radius: 100%;
		align-items: center;
		background-color: @color-white;
		border: 2px solid @color-cyan;
		display: flex;
		height: 13px;
		justify-content: center;
		left: -8px;
		position: absolute;
		top: 0;
		width: 13px;
		z-index: 1;

		.timeline-innercircle {
			border-radius: 100%;
			background-color: @color-cyan;
			height: 3px;
			width: 3px;
		}

		.timeline-delete {
			border-radius: 100%;
			cursor: pointer;
			position: absolute;
			background-color: @color-white;
			background-image: url("./assets/images/icons/cancel-red-64-64.png");
			background-repeat: no-repeat;
			background-size: 20px 20px;
			display: none;
			height: 20px;
			left: -2px;
			top: -2px;
			width: 20px;
			z-index: 3;
		}


	}

	&.nested-timeline-container {
		border-color: @color-yellow-dark;

		.timeline-outercircle {
			border-color: @color-yellow-dark;

			.timeline-innercircle {
				background-color: @color-yellow-dark;
			}
		}
	}
}

label {
	color: @color-gray-light1;
	font-size: @size-small4;
	font-weight: @weight-semibold;
	letter-spacing: 1px;
	margin-bottom: 7px;
	text-transform: uppercase;
}

.nav-arrow {
	background-position: center center;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	height: 24px;
	/*margin: 35px 0 0 15px;*/
	left: 40px;
	outline: none;
	position: absolute;
	top: 20px;
	width: 24px;
	z-index: 1;

	&.back-arrow {
		background-image: url("./assets/images/icons/arrow-back-gray-64-64.png");
	}

	&.front-arrow {
		background-image: url("./assets/images/icons/arrow-front-gray-64-64.png");
	}

	&.tips-nav-arrow {
		left: auto;
		right: 15px;
		top: 35px;
	}
}

.ng-arrow-wrapper {
	padding-right: 0;
	text-align: right;
	width: 15px;

	&:hover {

		.ng-arrow {
			border-color: @color-gray-medium5 transparent transparent;
		}
	}

	.ng-arrow {
		border-color: @color-gray-light1 transparent transparent;
	}
}

.dropdown {
	cursor: pointer;
	position: relative;

	&::before {
		border-color: @color-gray-light1 transparent transparent;
		border-style: solid;
		border-width: 6px 5px 2.5px;
		content: "";
		height: 0;
		position: absolute;
		right: -15px;
		width: 0;
		z-index: 3;
	}

	&:hover {

		&::before {
			border-color: @color-gray-medium5 transparent transparent;
		}
	}

	&+ .dropdown-list {
		display: none;
	}

	&.active {

		&::before {
			border-color: transparent transparent @color-gray-medium5;
			border-width: 2.5px 5px 6px;
		}

		&+ .dropdown-list {
			display: flex;
		}
	}
}

.dropdown-list {
	border-radius: 3px;
	background-color: @color-white;
	border: 1px solid @color-gray-lightest1;
	flex-direction: column;
	margin-left: 10px;
	position: absolute;
	right: 0px;
	top: 51px;
	width: 200px;
	z-index: 1;
}

.default-msg {
	color: @color-gray-light1 !important;
	font-size: @size-small1 !important;
	line-height: 1.7 !important;
	width: 100% !important;
}

.llm-content {
	display: flex;
	flex-direction: column;
	width: 100%;

	h1, h2, h3, h4, h5, h6 {
		display: flex !important;
		width: 100% !important;
		font-weight: @weight-semibold !important;
		line-height: 1.4 !important;
		margin: 5px 0 !important;
	}

	h1 {
		color: @color-black-dark4;
		font-size: @size-big1;
	}

	h2, h3, h4, h5, h6 {
		color: @color-gray-medium1;
		font-size: @size-big4;
	}

	p {
		color: @color-gray-medium1;
		display: flex;
		margin: 0 !important;
		padding: 10px 0 10px !important;
		width: 100%;
	}

	ul, ol {
		display: flex;
		flex-direction: column;
		margin: 7px 0 10px 0;
		padding-left: 20px;
	}

	ul {
		list-style: disc !important;
	}

	li {
		color: @color-gray-medium1 !important;
		padding: 5px 0 !important;

		&:last-child {
			padding-bottom: 10px !important;
		}

	}
	
	strong {
		color: inherit !important;
		font-weight: @weight-semibold !important;
	}
}

/* Subscriptions Tabs */
.tabs-container {
	background-color: @color-gray-light1;
	box-shadow: 1px 1px 5px @color-gray-light3;
	border-radius: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px 6px;
	width: auto;

	.tab-container {
		color: @color-white;
		cursor: pointer;
		display: flex;
		font-size: @size-small2;
		font-weight: @weight-semibold;
		letter-spacing: 0.05em;
		margin: 0 6px;
		padding: 10px 20px;
		text-transform: uppercase;
		width: auto;

		&:hover {
			background-color: @color-white;
			border-radius: 50px;
			color: @color-black-dark4;
			font-weight: @weight-bold;
		}

		&.active {
			background-color: @color-white;
			border-radius: 50px;
			color: @color-black-dark4;
			font-weight: @weight-bold;
		}
	}
}

/* Persona Edit */
.persona-form-container {
	background-color: @color-white;
/*	border: 1px solid @bordercolor-gray-light;*/
	border-radius: 5px;
/*	box-shadow: 0px 0px 3px @bordercolor-gray-light;*/
	display: flex;
	height: auto;
	margin: 0px 0 0 0px;
	padding: 50px 70px;
	width: 100%;

	.step {
		align-items: center;
		display: flex;
		flex-direction: row;

		.back {
			display: flex;
			margin-right: 10px;

			img {
				cursor: pointer;
				height: 16px;
				width: 16px;
			}
		}

		label {
			font-weight: @weight-bold !important;
			margin-bottom: 0 !important;
		}
	}

	.question {
		color: @color-cyan;
		display: flex;
		font-size: @size-ultrabigger2;
		font-weight: @weight-light;
		margin: 15px 0 30px;
		width: 100%;
	}

	.button-container {
		margin-top: 20px;
		justify-content: center;

		.btn.submit {
			min-width: 150px;
			padding: 7px 20px !important;
		}
	}

	.institute-form-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		.questions-wrapper {
			display: flex;
			flex-direction: row;
			width: 100%;
		}

		.question-wrapper-container {
			display: flex;
			flex-direction: column;
			width: 50%;
		}
	}
}

.question-tabs-container {
	align-items: center;
	border: 1px solid @color-gray-lightest1;
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	height: 40px;
	padding: 0 5px;

	.question-tab {
		background-repeat: no-repeat !important;
		background-size: 20px 20px !important;
		background-position: center !important;
		cursor: pointer;
		display: flex;
		height: 30px;
		width: 50px;

		&[data-type="text"] {
			background: url("./assets/images/conversations/text-gray-64-64.png");

			&.active {
				background: url("./assets/images/conversations/text-cyan-64-64.png");
			}
		}

		&[data-type="audio"] {
			background: url("./assets/images/conversations/audio-gray-64-64.png");

			&.active {
				background: url("./assets/images/conversations/audio-cyan-64-64.png");
			}
		}
	}
}


.view-doc-container {
	display: flex;

	span {
		background-image: url("./assets/images/icons/view-cyan-64-64.png");
		background-position: 7px 0px;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		border: 1px solid @color-cyan;
		border-radius: 10px;
		color: @color-cyan;
		display: flex;
		font-size: @size-small3;
		font-weight: @weight-bold;
		padding: 2px 0 2px 35px;
		width: 80px;
	}
}

.no-pointer {
	cursor: default !important;
}

.static-container {
	display: flex;
	flex-direction: column;
	font-size: @size-medium4;
	margin: 0 auto;
	padding: 130px 50px;
	width: 75%;

	strong {
		color: @color-gray-medium2;
		font-weight: @weight-bold;
	}

	a:hover {
		text-decoration: underline;
	}

	h2 {
		color: @color-black-dark2;
		font-size: @size-ultrabig4;
		font-weight: @weight-semibold;
		letter-spacing: 0.05em;
		line-height: 1.8;
		margin: 10px 0 15px;
		text-transform: uppercase;
	}

	h3 {
		color: @color-black-dark2;
		font-size: @size-medium2;
		font-weight: @weight-bold;
		letter-spacing: 0.08em;
		line-height: 1.8;
		margin: 10px 0 15px;
		text-transform: uppercase;
	}

	p {
		line-height: 1.8;
		text-align: justify;
	}

	.hoz_line {
		width: 100px;
	}

	ol {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		width: 100%;

		p {
			margin-bottom: 0.5rem;
		}
	}

	ul {
		display: flex;
		flex-direction: column;
		list-style: disc;
		margin-bottom: 15px;
		padding-left: 30px;
		width: 100%;
	}

	li {
		line-height: 1.8;
		padding-bottom: 10px;
		text-align: justify;

		ul, ol {
			margin: 10px 0 0px;
		}
	}

	table {
		margin: 20px 0 30px;

		tr {

			td {
				font-size: @size-small1;
				line-height: 1.8;
				padding: 20px 40px;
				text-align: left;
			}
		}

		thead {

			tr {
				background-color: @color-gray-light3 !important;
			}
		}

		tbody {

			tr td {

				&:first-child {
					width: 30%;
				}

				&:last-child {
					width: 10%;
				}
			}
		}

		&.cookies {

			tr {

				td {
					padding: 5px 20px;

					&:first-child {
						width: 20%;
					}

					&:last-child {
						width: 80%;
					}
				}
			}

		}
	}
}

/* Feedback and Bug Report Image */
.feedback-bug-fixed {
	border-radius: 2px;
	border:  1px solid @color-red-light;
	bottom: 10px;
	cursor: pointer;
	display: flex;
	opacity: 0.5;
	padding: 5px;
	position: fixed;
	right: 15px;
	transition: opacity 1s;
	z-index: @zindex-fixed;

	&:hover {
		opacity: 1;
	}

	img {
		height: 25px;
		width: 25px;
	}
}

.feedback-bug-container {
	display: flex;
	flex-direction: column;
	min-height: 75vh;
	padding: 40px 80px;
	width: 100%;

	ul.tabs {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;

		li.tab {
			border-radius: 3px;
			align-items: center;
			background-color: @color-white;
			border: 1px solid @color-gray-light1;
			color: @color-gray-light1;
			cursor: pointer;
			display: flex;
			font-size: @size-small2;
			font-weight: @weight-semibold;
			justify-content: center;
			letter-spacing: 0.1em;
			margin: 0 2px;
			padding: 5px 0;
			text-transform: uppercase;
			width: 130px;

			&.active {
				background-color: @color-cyan;
				border: 1px solid @color-cyan;
				color: @color-white;
			}
		}
	}

	.tab-content-container {
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		width: 100%;

		.tab-content {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
}

.fa {
	color: @color-gray-light4;
	font-size: @size-ultrabig4;
	margin: 0 2px;

	&.cyan-star {
		color: @color-yellow-dark;
	}
}

.go-dashboard {
	display: flex;
	flex-direction: row;
	padding: 25px 0 25px 25px;
	width: 100%;

	&:hover {
		.go-dashboard-text {
			text-decoration: underline;
		}
	}

	.go-dashbaord-icon {
		cursor: pointer;
		display: flex;
		width: 25px;
		padding-right:10px;
	}

	.go-dashboard-text {
		color: @color-gray-light1;
		cursor: pointer;
		display: flex;
		font-weight: @weight-semibold;
		font-size: @size-small3;
		text-transform: uppercase;
	}

}

/**********************AVA***********************/
.ava-container {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	margin-top: -30px;
	position: relative;
	width: 100%;

	.ava-head {
		align-items: center;
/*		background-color: rgba(159, 90, 253,0.01);*/
		background-color: @color-white;
		border: 1px solid @color-purple;
		border-bottom: 1px solid @color-white;
		border-radius: 3px;
		color: @color-purple;
		cursor:pointer;
		display: flex;
		justify-content: center;
		padding: 5px 15px;
		z-index: 1;

		img {
			width: 22px;
		}

		span {
			color: @color-purple;
			font-size: @size-small2;
			font-weight: @weight-semibold;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		&.ava-head-close{
			border-bottom: 1px solid @color-purple ;
		}
	}

	.ava-body {
		background-color: @color-white;
		border: 1px solid @color-purple;
		border-radius: 3px 0 3px 3px;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		margin-top: -1px;
		padding: 4% 5% 5%;
		width: 100%;

		.ava-intro-section {
			color: @color-gray-medium2;
			display: flex;
			flex-direction: column;
			font-size: @size-small1;
			line-height: 1.8;
			margin-bottom: 20px;
			text-align: justify;
			width: 100%;

			span {
				margin-bottom: 10px;
			}

			.ava-bold {
				color: @color-black-dark1;
				font-weight: @weight-semibold;
			}

			&.display-toggle {
				display: none;
			}
		}

		.close-icon {
			color: @color-gray-light1;
			cursor: pointer;
			font-size: @size-big4;
			font-weight: @weight-light;
			position: absolute;
			right: 20px;
			top: 30px;

			&:hover {
				color: @color-red-dark;
			}

			&.overlay-hide-icon{
				display: none;
			}
		}

		&.ava-close {
			padding: 0px;
			height: 0px;
			border: 0px;
		}

	}

	.generate-write-button {
		width: auto;
		height: 40px;
		background-color: rgba(159, 90, 253, 1);
		border: none;
		border-radius: 3px;
		font-weight: @weight-semibold ;
		font-size: @size-small1 ;
		color: @color-white ;
		padding: 10px 20px;
		text-transform: uppercase;
	}

	.overlay-text, .ava-mask {
		align-items: center;
		background-color: rgba(255, 255, 255, 0.9);
		border: 1px solid rgba(159, 90, 253, 0.2);
		border-radius: 5px 0 5px 5px;
		color: @color-gray-medium1;
		display: flex;
		flex-direction: column;
		font-size: @size-bigger2;
		height: calc(100% - 29px - 20px);
		justify-content: center;
		padding: 4% 5%;
		pointer-events: visible;
		position: absolute;
		top: 29px;
/*		transform: translate(-50%, -50%);*/
		width: 100%;
		z-index: 10;

		.close-icon {
			color: @color-gray-light1;
			cursor: pointer;
			font-size: @size-bigger4;
			font-weight: @weight-light;
			position: absolute;
			right: 15px;
			top: 10px;

			&:hover {
				color: @color-red-dark;
			}
		}

		&.close-overlay {
			display: none;
		}

		.mask-content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 70%;

			.title {
				color: @color-gray-medium1;
				font-size: @size-ultrabig3;
				text-align: center;
			}

			.desc {
				color: @color-gray-medium6;
				font-size: @size-medium3;
				margin-top: 10px;
				text-align: center;
			}

			button {
				margin-top: 20px !important;
				padding: 10px 20px !important;
				width: 200px !important;
			}
		}
	}
}

.ava-button-container {
	display: flex;
	margin-top: 20px;
	width: auto;

	.ava-button {
/*		background-color: rgba(159, 90, 253, 0.3);*/
		background-color: transparent !important;
		border: 1px solid @color-purple !important;
		border-radius: 3px;
/*		color: @color-white !important;*/
		color: @color-purple !important;
		display: flex;
		font-size: @size-small3 !important;
		font-weight: @weight-semibold !important;
		letter-spacing: 1px;
		padding: 8px 15px 9px;
		text-transform: uppercase;
		width: auto;

		&:hover {
			background-color: @color-purple !important;
			color: @color-white !important;
		}

	}

	&.rephrase {
		justify-content: flex-end;

		.ava-button {
			background-color: transparent !important;
			color: @color-purple !important;
			font-size: @size-small4 !important;
			padding: 5px 10px 6px;

			&:hover {
				background-color: @color-purple !important;
				color: @color-white !important;
			}
		}

	}

	&.display-toggle{
		display: none;
	}
}

.loading-content {
	display: flex;
	width: 100%;
}

.editor, .ava-editor, .ava-body {
	border-radius: 5px;
	display: flex;
	width: 100%;
	flex-direction: column;

	.angular-editor-toolbar {
		background-color: @bgcolor-default !important;
		border-bottom: none !important;
		border-radius: 5px 5px 0 0 !important;
		padding: 5px 5px 3px !important;

		.angular-editor-toolbar-set {

		}
	  }

	.angular-editor-wrapper {
		border: none !important;

		.angular-editor-textarea {
			border: 1px solid @bordercolor-input;
			border-radius: 0 0 5px 5px;
			color: @color-cyan;
			font-size: @size-medium3;
			height: auto !important;
			margin-top: 0px !important;
			min-height: 7rem !important;
			line-height: 1.8;
			padding: 20px 25px !important;
/*			text-align: justify;*/

			ul {
				display: block !important;
				list-style-type: disc !important;
				margin-block-start: 10px !important;
				margin-block-end: 10px !important;
				margin-inline-start: 0px !important;
				margin-inline-end: 0px !important;
				padding-inline-start: 20px !important;

				li {
					display: list-item !important;
					text-align: -webkit-match-parent !important;
					padding-top: 10px !important;
				}
			}

			ol {
				display: block !important;
				margin-block-start: 10px !important;
				margin-block-end: 10px !important;
				margin-inline-start: 0px !important;
				margin-inline-end: 0px !important;
				padding-inline-start: 20px !important;

				li {
					display: list-item !important;
					text-align: -webkit-match-parent !important;
					padding-top: 10px !important;
				}
			}
		}

		.angular-editor-placeholder {

		}
	}

	.angular-editor-button {
		background-color: rgba(241, 244, 244, 1) !important;

		.fa {
			color: black !important;
		}
	}

	.angular-editor-button.active {
		background-color: lightblue !important;
	}


	.angular-editor {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		border-top: none !important;
	}


	.NgxEditor__MenuBar{
	  border-top-left-radius: 4px !important;
	  //border-top-right-radius: 4px;
	  //border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	  background-color: rgba(241, 244, 244, 1) !important;
	  border: 1px solid rgba(159, 90, 253, 1) !important;
	  border-bottom: none !important;
	}

	.NgxEditor {
	  border-top-left-radius: 0 !important;
	  border-top-right-radius: 0 !important;
	 // border: none;
	 border: 1px solid rgba(159, 90, 253, 1) !important;
	 border-top: none !important;

	}

	.CodeMirror {
	  border: 1px solid #eee;
	  height: auto;
	  margin-bottom: 0.7rem;

	  pre {
		white-space: pre !important;
	  }
	}
}

.delete-container {
	color: @color-red-light;
	cursor: pointer;
	display: none;
	font-size: @size-small3;

	&:hover {
		font-weight: @weight-semibold;
	}
}

/*************************************************
	 Media Queries Starts Here
*************************************************/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.view-section-conatiner {
		margin-bottom: 15px;
		padding-left: 0px;
		padding-right: 0px;

		.view-section-title {
			font-weight: @weight-normal;
			font-size: 16px;
			color: @color-black-dark4;
			text-align: justify;
			text-justify: inter-word;
			line-height: 24px;
			margin-bottom: 10px;
		}

		.view-section-subtitle {
			font-weight: @weight-normal ;
			font-size: 11px ;
			color: @color-gray-medium4 ;
			letter-spacing: 0.05em;
		}

		.view-section-description {
			font-weight: @weight-normal;
			font-size: 13px ;
			color: @color-gray-medium2 ;
			line-height: 1.6;
			text-align: justify;
			margin-top: 15px;
		}
	}

	.view-align-date {
		text-align: left;
		text-transform: uppercase;
	}
	.auth-container {

		.box-container {

			.box-content {
				padding:  40px 40px;
			}
		}
	}

	.feedback-bug-container {
		padding: 7% 10%;
	}

	.otp-input {
		height: 40px !important;
	}

	.timeline-container {
		border-left: 2px solid @color-cyan;
		display: flex;
		flex-direction: column;
		width: 100%;

		> .field-group-wrapper {
			padding-left: 50px;
			position: relative;
		}

		.timeline-outercircle {
			border-radius: 100%;
			align-items: center;
			background-color: @color-white;
			border: 2px solid @color-cyan;
			display: flex;
			height: 13px;
			justify-content: center;
			left: -8px;
			position: absolute;
			top: 0;
			width: 13px;
			z-index: 1;

			.timeline-innercircle {
				border-radius: 100%;
				background-color: @color-cyan;
				height: 3px;
				width: 3px;
			}

			.timeline-delete {
				border-radius: 100%;
				cursor: pointer;
				position: absolute;
				background-color: @color-white;
				background-image: url("./assets/images/icons/cancel-red-64-64.png");
				background-repeat: no-repeat;
				background-size: 20px 20px;
				display: none;
				height: 20px;
				left: -2px;
				top: -2px;
				width: 20px;
				z-index: 3;
			}


		}

		&.nested-timeline-container {
			border-color: @color-yellow-dark;

			.timeline-outercircle {
				border-color: @color-yellow-dark;

				.timeline-innercircle {
					background-color: @color-yellow-dark;
				}
			}
		}
	}

	.menu-tips-container {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 0;
		justify-content: flex-end;
		right: 0;
		position: fixed;
		top: 70px;
		width: 100%;
		z-index: 2;
	}

	/*	AVA Container */
	.ava-container {

		.ava-head {

		}

		.ava-body {
			padding: 6% 8% 8%;
		}
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
	.view-section-conatiner {
		margin-bottom: 20px;
		padding-left: 0px;
		padding-right: 0px;

		.view-section-title {
			font-weight: @weight-normal;
			font-size: @size-big4;
			color: @color-black-dark4;
			text-align: justify;
			text-justify: inter-word;
			line-height: 34px;
			margin-bottom: 10px;
		}

		.view-section-subtitle {
			font-weight: @weight-normal ;
			font-size: @size-small1 ;
			color: @color-gray-medium4 ;
			letter-spacing: 0.05em;
		}

		.view-section-description {
			font-weight: @weight-normal;
			font-size: @size-medium4 ;
			color: @color-gray-medium2 ;
			line-height: 1.8;
			text-align: justify;
			margin-top: 15px;
		}
	}

	.view-align-date {
		text-align: left;
		text-transform: uppercase;
	}
	.auth-container {

		.box-container {

			.box-content {
				padding: 50px 60px;
			}
		}
	}

	.feedback-bug-container {
		padding: 7% 10%;
	}
	.menu-tips-container {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 0;
		justify-content: flex-end;
		right: 0;
		position: fixed;
		top: 70px;
		width: 100%;
		z-index: 2;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.view-section-conatiner {
		margin-bottom: 30px;
		padding-left: 30px;
		padding-right: 30px;

		.view-section-title {
			font-weight: @weight-normal;
			font-size: @size-big4;
			color: @color-black-dark4;
			text-align: justify;
			text-justify: inter-word;
			line-height: 34px;
			margin-bottom: 10px;
		}

		.view-section-subtitle {
			font-weight: @weight-normal ;
			font-size: @size-small1 ;
			color: @color-gray-medium4 ;
			letter-spacing: 0.05em;
		}

		.view-section-description {
			font-weight: @weight-normal;
			font-size: @size-medium4 ;
			color: @color-gray-medium2 ;
			line-height: 1.8;
			text-align: justify;
			margin-top: 15px;
		}
	}
	.view-align-date {
		text-align: left;
		text-transform: uppercase;
	}

	.menu-tips-container {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 0;
		justify-content: flex-end;
		right: 0;
		position: fixed;
		top: 70px;
		width: 100%;
		z-index: 2;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.view-section-conatiner {
		margin-bottom: 30px;
		padding-left: 30px;
		padding-right: 30px;

		.view-section-title {
			font-weight: @weight-normal;
			font-size: @size-big4;
			color: @color-black-dark4;
			text-align: justify;
			text-justify: inter-word;
			line-height: 34px;
			margin-bottom: 10px;
		}

		.view-section-subtitle {
			font-weight: @weight-normal ;
			font-size: @size-small1 ;
			color: @color-gray-medium4 ;
			letter-spacing: 0.05em;
		}

		.view-section-description {
			font-weight: @weight-normal;
			font-size: @size-medium4 ;
			color: @color-gray-medium2 ;
			line-height: 1.8;
			text-align: justify;
			margin-top: 15px;
		}
	}
	.view-align-date {
		text-align: left;
		text-transform: uppercase;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.view-section-conatiner {
		margin-bottom: 30px;
		padding-left: 30px;
		padding-right: 30px;

		.view-section-title {
			font-weight: @weight-normal;
			font-size: @size-big4;
			color: @color-black-dark4;
			text-align: justify;
			text-justify: inter-word;
			line-height: 34px;
			margin-bottom: 10px;
		}

		.view-section-subtitle {
			font-weight: @weight-normal ;
			font-size: @size-small1 ;
			color: @color-gray-medium4 ;
			letter-spacing: 0.05em;
		}

		.view-section-description {
			font-weight: @weight-normal;
			font-size: @size-medium4 ;
			color: @color-gray-medium2 ;
			line-height: 1.8;
			text-align: justify;
			margin-top: 15px;
		}
	}

	.view-align-date {
		text-align: right;
		text-transform: uppercase;
	}
}
















.visualcv-icons {
	color: @color-cyan;
	font-size: @size-medium4;
	width: 18px;
}

.mandatory-field {
	color:@color-gray-light1;
	font-size: @size-small1;
	padding: 0 0 0 2px;
}

.ngx-ic-source-image {
	width: 250px !important;
}

.error-message {
	color: #ff6b6b;;
	font-size: 12px;
	margin-top: 10px;

}
.hide-overflow-y{
    overflow-y: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


