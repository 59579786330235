@import './_variables.less';

.form-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

/* Make it form-messages-container */
.form-errors-container {
}

.form-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	.field-group-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		.field-wrapper {
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;
			position: relative;
			width: 45%;

			&.field-wrapper-textarea {
				width: 100%;
			}

			&.num-wrapper {
				position: relative;
				width: 100% !important;
			}

			&.num-field-wrapper {
				flex-direction: row;
				margin-bottom: 20px;
				position: relative;
				width: 100%;

				&:hover {

					.delete-field-wrapper {
						display: flex;
					}
				}

				.delete-field-wrapper {
					background-color: @color-white;
					background-image: url("../../../assets/images/icons/cancel-red-64-64.png");
					background-position: center center;
					background-repeat: no-repeat;
					background-size: 32px 32px;
					cursor: pointer;
					display: none;
					height: 32px;
					left: 0;
					position: absolute;
					top: 0;
					width: 32px;
				}

				.field-num {
					font-size: @size-ultrabigger4;
					font-weight: @weight-light;
					margin-top: -8px;
					width: 60px;
				}

				.field-group-wrapper {
					flex-direction: column;

					.field-group-wrapper {
						flex-direction: row;
						justify-content: space-between;
						width: 100%;

						.field-wrapper {
							width: 45%;
						}
					}
				}
			}

			.field-num-wrapper {
/*				align-items: center;*/
				display: flex;
				flex-direction: row;
				position: relative;
				width: 100%;

				.field-num {
					font-size: @size-ultrabigger4;
					font-weight: @weight-light;
/*					margin-top: 26px;*/
					width: 60px;
				}	

				.field-normal-wrapper {
					width: calc(100% - 60px);
				}

				.delete-field-wrapper {
					background-image: url("../../../assets/images/icons/cancel-red-64-64.png");
					background-position: center right;
					background-repeat: no-repeat;
					background-size: 20px 20px;
					cursor: pointer;
					display: none;
					height: 50px;
					width: 70px;
				}
			}

			label {
				color: @color-gray-light1;
				font-size: @size-small4;
				font-weight: @weight-semibold;
				letter-spacing: 1px;
				margin-bottom: 5px;
				text-transform: uppercase;

				&.shade {
					color: @color-gray-medium5;
				}
			}

			.toggle-password {
				cursor: pointer;
				font-size: @size-medium1;
				position: absolute;
				right: 0;
				top: 28px;

				&:hover {
					color: @color-cyan;
				}
			}

			.field-select-grade {
				.ng-select {
					.ng-select-container {
						border: 1px solid lightgrey !important;
						font-size: 13px !important;
						text-align: center !important;
						border-radius: 30px !important;
						min-height: 20px !important;
						max-width: 140px;
						padding-left: 20px;
						margin-left: 40px;
					}
				}
			}
			

			.field-select {
				.ng-select {
					.ng-select-container {
						border: 1px solid lightgrey !important;
						font-size: 13px !important;
						text-align: center !important;
						border-radius: 30px !important;
						min-height: 20px !important;
						max-width: 150px;
						padding-left: 20px;
					}
				}
			}

			.ng-select {
				border: none;
				outline: none;
				/*box-shadow: none;*/

				&.ng-skill {

					.ng-select-container {
						background-color: transparent !important;
					}
				}

				&.shade {

					.ng-select-container {
						border-bottom: 1px solid @bordercolor-input-shade;

						.ng-placeholder {
							color: @color-input-field-placeholder-shade;
						}
					}
				}

				&.ng-select-focused {
					border-bottom: 1px solid @color-cyan;
					box-shadow: none !important;
					
					&:not(.ng-select-opened) > .ng-select-container {
						border: none;
						border-bottom: 1px solid @bordercolor-input;
						box-shadow: none !important;
					}
				}

				&.ng-select-opened { 
					
					> .ng-select-container {

						.ng-arrow-wrapper {

							&:hover {

								.ng-arrow {
									border-color: transparent transparent @color-gray-medium5;
								}
							}
						}

						.ng-arrow {
							border-color: transparent transparent @color-gray-light1;

							&:hover {
								border-color: transparent transparent @color-gray-medium5;
							}
						}
					}
				}

				.ng-select-container {
					border-radius: 0;
					box-shadow: none !important;
					background-color: @color-white;
					border: none;
					border-bottom: 1px solid @bordercolor-input;
					color: @color-input-field-focus;
					cursor: text;
					font-size: @size-medium2;
					font-weight: @weight-normal;
					padding: 0;
					min-height: 45px !important;

					&:hover {
						box-shadow: none !important;
					}
					
					.ng-value-container {
						padding-left: 0;
					}

					.ng-placeholder {
						color: @color-input-field-placeholder;
						font-weight: @weight-light;
					}

					.ng-input {
						padding-left: 0px;

						input {
							padding: 0;
						}
					}
				}

				.ng-dropdown-panel {
					border-radius: 2px;
					box-shadow: none !important;
					border-color: @color-gray-lightest1;
					margin-top: 0px;

					.ng-dropdown-panel-items {

						/* CSS to move add tag label to the first option start*/
						//padding-top: 50px !important;
						//position: relative !important;
						/* CSS to move add tag label to the first option end*/

						.ng-option {
							border-bottom: 1px solid @color-gray-lightest1;
							color: @color-gray-medium2;
							font-size: @size-small1;
							padding: 15px 25px;

							&:first-child {
								border-radius: 2px 2px 0 0c;
								/*border-bottom: none;*/
							}

							&:last-child {
								border-radius: 0 0 2px 2px;
								border-bottom: none;
							}

							&.ng-option-marked {
								background-color: @color-cyan;
								color: @color-white;
							}
						}

						/* CSS to move add tag label to the first option start*/

						// .ng-option:has(.ng-tag-label) {
							
						// 	position: absolute !important;
						// 	top: 0px !important;
						// 	left: 0px !important;
						// 	width: 100% !important;
						// 	z-index: 1000000000 !important;
						// 	background-color: #e3e5e5;
						//   }

						/* CSS to move add tag label to the first option end*/
					}
				}

				.ng-clear-wrapper {
					color: @color-gray-light1;

					&:hover {

						.ng-clear  {
							color: @color-red-dark;
						}
					}
				}

				.ng-arrow-wrapper {
					padding-right: 0;
					padding-top: 1px;
					text-align: right;
					width: 15px;

					&:hover {

						.ng-arrow {
							border-color: @color-gray-medium5 transparent transparent;
						}
					}

					.ng-arrow {
						border-color: @color-gray-light1 transparent transparent;
					}
				}
			}

			.ava-text {
				font-size: @size-medium2 !important;	
				
				.ng-select {
					
					.ng-select-container {
    					font-size: @size-medium3 !important;
					}
				}
				
				.ng-select input {
    				font-size: @size-medium3 !important;
				}

				.ng-dropdown-panel-items {
					
					.ng-option {
						border-bottom: 1px solid @color-gray-lightest1;

						&.ng-option-marked {
							background-color: #9F5AFD !important;
							color: @color-white;
						}
					}
				}
			}

			ngx-intl-tel-input {

				&.ng-touched.ng-invalid {

					.iti {
						border-bottom: 1px solid @color-input-field-error !important;
					}
				}

				&.telDisabled {
					
					input[type="tel"]  {
						opacity: 0.5;
						pointer-events: none;
					}
				}
			}

			::placeholder {
				color: @color-input-field-placeholder;
				font-weight: @weight-light;
				letter-spacing: 1px;
			}

			input.field-input, textarea.field-input {
				background-color: @color-white;
				border: none;
				border-bottom: 1px solid @bordercolor-input;
				color: @color-input-field-focus;
				font-size: @size-medium3;
				font-weight: @weight-normal;
				padding: 0;
				height: 40px;

				&:focus {
					color: @color-input-field-focus;
					border-bottom: 1px solid @color-input-field-border-highlight !important;
					outline: none;
				}

				&:disabled {
					opacity: 0.5 !important;
				}

				&.shade {
					background-color: transparent !important;
					border-bottom: 1px solid @bordercolor-input-shade;

					.ng-placeholder {
						color: @color-input-field-placeholder-shade;
					}
				}

				&.ng-touched.ng-invalid {
					border-bottom: 1px solid @color-input-field-error !important;
				}

				&[readOnly]  {
					opacity: 0.5;
				}

				&.date-picker {
					background-image: url("../../../assets/images/icons/calendar-gray-64-64.png");
					background-position: center right;
					background-repeat: no-repeat;
					background-size: 20px 20px;
					cursor: pointer;

					&[readOnly]  {
						opacity: 1;
					}
				}
			}

			textarea.field-input {
				height: auto !important;
				min-height: 50px !important;
				line-height: 1.8;
				margin-top:  8px !important;
				padding: 0 !important;
			}

			textarea {

				&.field-textarea-error {
					border-bottom: 1px solid @color-input-field-error;
				}
			}

			.field-error {
				color: @color-red-dark;
				display: flex;
				flex-direction: column;
				font-size: @size-small2;
				margin-top: 10px;

				span {
					padding-bottom: 7px;

					&:last-child {
						padding-bottom: 0px;
					}
				}
			}

			&.checkbox-container {
				align-items: center;
				cursor: pointer;
				display: flex;
				margin-bottom: 30px;
				position: relative;
				width: 100%;

				&:hover input ~ .checkmark {
					background-image: url('../../images/icons/checkmark-bg-cyan-64-64.png');
				}

				input {
					cursor: pointer;
					height: 0;
					opacity: 0;
					position: absolute;
					width: 0;
				}

				.text {
					color: @color-gray-light2;
					font-size: @size-small2;
					font-weight: @weight-normal;
					line-height: 20px;
					padding-left: 30px;
					padding-top: 5px;

					a:hover {
						text-decoration: underline;
					}
				}

				.checkmark {
					background-image: url('../../images/icons/checkmark-bg-gray-64-64.png');
					background-repeat: no-repeat;
					background-size: 20px 20px;
					height: 20px;
					left: 0;
					position: absolute;
					top: 0;
					width: 20px;
				}

				input:checked ~ .checkmark {
					background-image: url('../../images/icons/checkmark-bg-cyan-64-64.png');
				}

				.checkmark:after {
					background-image: url('../../images/icons/checkmark-bg-cyan-64-64.png');
					content: '';
					display: none;
					position: absolute;
					/*height: 20px;*/
					/*width: 20px;*/
				}

				input:checked ~ .checkmark:after {
					display: block;
				}
			}

			.iti {
				align-items: center;
				border-bottom: 1px solid @color-input-field-border !important;
				color: @color-input-field-focus;
				display: flex;
				flex-direction: row;
				font-size: @size-big2;
				font-weight: @weight-normal;
				height: 45px;
				padding: 0;
				width: 100%;

				input#country-search-box {
					padding: 10px 15px !important;
				}

				.iti__flag-container {
					display: flex;
					flex-direction: row;
					justify-content: center;
					padding: 2px !important;
					width: 100px !important;

					.iti__selected-flag {
						flex-direction: row;
						justify-content: center;
						padding: 0 5px !important;
						width: auto !important;

						.iti__flag {
							margin: 0 0 !important;
						}

						.selected-dial-code {
							color: @color-black-dark4;
							font-size: @size-medium4;
							font-weight: @weight-semibold;
							margin: 0 15px; 
						}

						.iti__arrow {
							border-top: 4px solid @color-gray-light1;
							margin: 0px;
						}
					}

					.country-dropdown {
						border-radius: 2px !important;
						border: 1px solid @bordercolor-input;
						flex-direction: column;
						width: 270px !important;

						ul.iti__country-list {
							box-shadow: none;
							display: flex;
							flex-direction: column;
							width: 100%;

							.search-container {
								width: 100%;

								input#country-search-box {
									padding: 10px 15px !important;
								}
							}

							li.iti__country {
								padding: 10px 20px;
								width: 100%;

								&:hover {
									background-color: @color-cyan;

									span.iti__country-name, span.iti__dial-code {
										color: @color-white;
									}
								}

								.iti__flag-box {
									margin-right: 15px;
								}

								span.iti__country-name {
									color: @color-gray-medium1;
									font-size: @size-medium4;
								}
							}
						}
					}
				}

				input[type=tel] {
					background-color: @color-white !important;
					border: none !important;
					color: @color-input-field-focus;
					font-size: @size-big2;
					font-weight: @weight-normal;
					padding: 2px 0 0 110px !important;
					width: 100% !important;
				}				
			}
		}
	}
}

.button-container {
	align-items: center;
	display: flex;
	margin-top:10px;
	width: 100%;

	.btn {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-size: @size-small1;
		justify-content: center;
		letter-spacing: 0.07em;
		padding: 20px 100px;
		position: relative;
		max-width: 100%;

		&:hover {
			box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 5px;
		}
	}
}

/* UI Switch CSS*/
.switch {
	background: @color-red-dark;
	border: none;
	outline: none;
	height: 30px;
	/*width: 100px;*/

	&.checked {
		background: @color-cyan;

		small {
			right: 2px !important;
		}
	}

	label.switch-pane {
		line-height: 30px;
		margin-bottom: 0;
	}

	span {
		color: @color-white;
		line-height: 30px;
	}

	small {
		box-shadow: none;
		top: 2px;
	}

	&.switch-medium small {
		height: 26px;
		right: calc(100% - 28px);
		width: 26px;
	}
}

.switch.switch-medium > .switch-pane > span {
	font-size: @size-small2;
	font-weight: @weight-semibold;
}

/* BS DATEPICKER */
bs-datepicker-container  {

	.bs-datepicker {
		box-shadow: none;

		.bs-datepicker-container {
			padding: 0;

			.bs-datepicker-head {
				border-radius: 2px 2px 0 0;
				background-color: @color-cyan;
			}

			.bs-datepicker-body {
				border-radius: 0 0 2px 2px;

				table {
					border: none;
					
					tr {
						border-bottom: none;

						&:last-child {
							border-bottom: none;
						}

						&:nth-child(2n-1) {
							background-color: transparent;
						}
					}

					th {
						background-color: transparent;
						border-right: none;
						font-size: @size-small4;
						font-weight: @weight-semibold;
						letter-spacing: 0.05em;
						padding: 5px 0;

						&:last-child {
							border-right: none;
						}
					}

					td {
						border-right: none;
						padding: 1px 0;

						&:last-child {
							border-right: none;
						}

						span {

							&.selected  {
								background-color: @color-cyan;
							}
						} 
					}
				}
			}
		}
	}
}

.add-container-wrapper {
	align-items: flex-start;
	display: flex;
	margin-top: 20px;
	width: 100%;

	.add-container {
		align-items: center;
		background-image: url('../../images/icons/add-cyan-64-64.png');
		background-position: 10px 9px;
		background-repeat: no-repeat;
		background-size: 16px 16px;
		border: 1px solid @color-cyan !important;
		border-radius: 3px;
		color: @color-cyan;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		padding: 10px 15px 10px 25px;
		font-size: @size-small4;
		font-weight: @weight-bold;
		letter-spacing: 1px;
		line-height: @size-small1;
		text-transform: uppercase;

		img {
			height: 18px;
			width: 18px;
		}

		span {
			color: @color-cyan;
			font-size: @size-small3;
			font-weight: @weight-semibold;
			letter-spacing: 0.05em;
			line-height: @size-small1;
			padding-left: 10px;
			text-transform: uppercase;
		}

		&:hover {
			background-color: @color-cyan;
			background-image: url('../../images/icons/add-white-64-64.png');
			color: @color-white;

			span {
				color: @color-white;
			}
		}
	}
}

.field-group-wrapper.add-container-wrapper {
	align-items: flex-start;
	justify-content: center;

	.add-container {
		justify-content: center;

		img {
			height: 18px;
			width: 18px;
		}

		span {
			font-size: @size-medium4;
			line-height: @size-medium4;
			padding-left: 10px;
		}
	}
}

.buttons-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px !important;
	width: 100%;

	.button-container {
		margin-top: 0 !important;
		width: auto !important;

		.btn {
			box-shadow: none !important;
			height: 40px !important;
			padding: 0 20px !important;
		}

		.btn-link {
			padding: 0 !important;
		}
	}
}

.control-group {
	display: flex;
	flex-direction: column;
	width: 100%;

	.control {
		color: @color-gray-medium2;
		cursor: pointer;
		display: flex;
		margin-bottom: 15px;
		padding: 1px 0 0 30px;
		position: relative;
		text-transform: capitalize;

		input {
			position: absolute;
			z-index: -1;
			opacity: 0;
		}
		
		.control__indicator {
			align-items: center;
			background: @color-gray-lightest1;
			display: flex;
			justify-content: center;
			height: 16px;
			left: 0;
			position: absolute;
			top: 2px;
			width: 16px;
		}

		&.control--radio .control__indicator {
			border-radius: 50%;
		}

		&:hover {

			input ~ .control__indicator, .control input:focus ~ .control__indicator {
				background: @color-gray-light4;
			}
		}

		input:checked ~ .control__indicator {
			background: @color-cyan;
		}

		&:hover {

			input:not([disabled]):checked ~ .control__indicator, .control input:checked:focus ~ .control__indicator {
				background: @color-cyan;
			}
		}

		input:disabled ~ .control__indicator {
			background: @color-gray-lightest1;
			opacity: 0.6;
			pointer-events: none;
		}

		.control__indicator:after {
			content: '';
			position: absolute;
			display: none;
		}

		input:checked ~ .control__indicator:after {
			display: block;
		}

		&.control--checkbox .control__indicator:after {
			left: 7px;
			top: 2px;
			width: 6px;
			height: 12px;
			border: solid @color-white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
		
		&.control--checkbox input:disabled ~ .control__indicator:after {
			border-color: @color-cyan;
		}
		
		&.control--radio .control__indicator:after {
			border-radius: 50%;
			background: @color-white;
			height: 6px;
			width: 6px;
		}
		
		&.control--radio input:disabled ~ .control__indicator:after {
			background: @color-cyan;
		}
	}
}

.social-login-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;

	.or-sep {
		align-items: center;
		color: @color-gray-light1;
		display: flex;
		flex-direction: row;
		font-size: @size-small2;
		justify-content: center;
		margin: 40px 0 30px;
		width: 100%;

		.hoz_line {
			border-bottom-color: @color-gray-light4;
			margin: 0;
			width: 20px;
		}

		span {
			padding: 0 10px;
		}
	}
}

/* Radio Button Custom */
.radio {
	cursor: pointer;
	float: left;
	margin-right: 40px;

	&[data-disabled='true'] {
		opacity: 0.5;

	}

	&[data-disabled='false'] {
		opacity: 1;
	}

	.radio_button {
		background-color: @color-white;
		border: 2px solid @color-gray-light1;
		border-radius: 50px;
		float: left;
		height: 20px;
		width: 20px;

		span {
			opacity: 0;
			border-radius: 50%;
			background-color: @color-gray-light1;
			float: left;
			height: 8px;
			margin: 4px;
			width: 8px;
		}
	}

	.radio_text {
		color: @color-gray-medium1;
		float: left;
		font-size: @size-small1;
		font-weight: 400;
		margin: 3px 0 0 10px;
		text-transform: uppercase;
	}
}

.active_radio {
	
	.radio_button {
		border: 2px solid @color-cyan;

		span {
			opacity: 1;
			background-color: @color-cyan;
		}
	}
}
/* Ends Custom Radio Button */

/* Media Queries Starts Here */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	
	.form-wrapper {

		.field-group-wrapper {
			flex-direction: column;

			.field-wrapper {
				width: 100%;

				.iti .iti__flag-container .country-dropdown {
					width: 210px !important;
				}
			}
		}
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {

	.form-wrapper {

		.field-group-wrapper {
			flex-direction: column;

			.field-wrapper {
				width: 100%;

				.iti .iti__flag-container .country-dropdown {
					width: 400px !important;
				}
			}
		}
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {

	
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
